import { useState } from "react";
import LoginForm from "../LoginForm/LoginForm";
import styles from "./Main.module.scss"

const Main = () => {
    const [error, setError] = useState(false);

    return (
        <main className={styles.mainCont}>
            {error ? <div className={styles.errorBox}>
                <h2>The following errors have occurred</h2>
                <p>1. Login Error.</p>
            </div> : ''}
            <div className={styles.mainBox1}>
                <a href="">For English click here</a>
                <a href="">En Français, cliquer ici</a>
                <a href="">Para Español Presione Aquí</a>
            </div>
            <div className={styles.mainBox2}>
                <div className={styles.flexing}>
                    <LoginForm setError={setError} />
                    <p>By accessing this system you accept the&nbsp;
                        <a href="">Customer Terms of Use</a>
                        &nbsp;and&nbsp;
                        <a href="https://www.efsllc.com/privacy-notice/">Privacy Policy.</a>
                    </p>
                </div>
            </div>
            <div className={styles.mainBox3}>
                <p>User/Carrier ID and Password are case sensitive</p>
            </div>
        </main>
    )
}
export default Main;